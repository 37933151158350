<!--
 * @Author: wxr
 * @Date: 2021-12-08 19:26:22
 * @LastEditTime: 2022-05-23 16:38:45
 * @LastEditors: Please set LastEditors
 * @Description: 高德地图
 * @FilePath: src\components\BaiduMap\GdMap.vue
-->
<template>
  <div class="el-gd-map">
    <div class="map-box">
      <!-- 高德地图不能正常运行，请检查秘钥是否正确！ -->
      <div v-show="mapError">
        {{
          $t(
            "label.gaode.map.cannot.work.normally.please.check.whether.the.secret.key.is.correct"
          )
        }}
      </div>
      <div class="search" v-if="showSearchBar && !isInfoMap && !isHide">
        <!-- 搜索框 -->
        <div class="search1">
          <div class="show-address">
            {{ checkedInfo.formattedAddress || "未选择地址" }}
          </div>
          <el-button-group>
            <!-- 确定 -->
            <el-button
              type="primary"
              @click="handleChecked"
              class="btn"
              style="background: #006dcc"
              >{{ $t("label.ems.confirm") }}</el-button
            >
            <!-- 取消 -->
            <!-- 先隐藏 -->
            <el-button @click="handleClear" v-if="false" class="btn">{{
              $t("label_emailtocloudcc_clear")
            }}</el-button>
          </el-button-group>
        </div>
      </div>
      <!-- 搜索数据 -->
      <div class="search-box" v-if="!isInfoMap">
        <div class="search-input">
          <el-input
            type="text"
            :placeholder="$t('label.searchkey')"
            suffix-icon="el-icon-search"
            v-model="searchKey"
            @blur="handlebulr"
            @focus="handleFocus"
          ></el-input>
        </div>
        <!-- 下拉框 -->
        <div class="search-result" v-if="showSearch && searchList.length > 0">
          <div
            v-for="(item, index) in searchList"
            :key="index"
            class="search-result-item"
            @click="chooseLocation(item)"
          >
            {{ item.name || item.formatted_address }}
            <span style="color: #999; padding-left: 5px">
              {{ item.district ? "(" + item.district + ")" : "" }}
            </span>
          </div>
        </div>
      </div>
      <!-- 关闭按钮 -->
      <div class="close_box" @click.stop="bigClose" v-if="showSearchBar && !isHide">
        <svg class="icon" aria-hidden="true">
          <use fill="#8a8a8a" href="#icon-a-Crosssign" />
        </svg>
      </div>
      <!-- 全屏展示按钮 -->
      <div
        class="enlarge_box"
        @click="enlargeFun"
        :style="{ top: mapDialogTop }"
        v-if="!isInfoMap && !isHide"
      >
        <svg class="icon" aria-hidden="true">
          <use :href="iconImg" />
        </svg>
        <!-- 全屏 -->
        <span>{{ $t("label.tabpage.fullscreenz") }}</span>
      </div>
      <!-- 高德地图 -->
      <div
        v-show="isMap"
        :id="`js-container${lineIndex}`"
        class="map"
        :style="{ width: '100%', height: mapHeight }"
      ></div>
      <!-- 谷歌地图 -->
      <div
        v-show="!isMap"
        :id="`gg-container${lineIndex}`"
        class="map"
        :style="{ width: '100%', height: mapHeight }"
      ></div>
      <div id="poiPanelId" v-show="poiPanel && !isInfoMap"></div>
    </div>
  </div>
</template>

<script>
/**
 * 文件管理
 * 功能：
 * 1、地图展示。
 * 2、搜索。
 * 3、确定。
 * 4、取消。
 * 5、全屏。
 * 6、关闭。
 * 逻辑：
 * 1、初始化地图。
 * 2、搜索地址查询相关的地址列表。
 * 3、点击确定回传信息汇总。
 * 4、点击全屏按钮设置样式展示全屏地图。
 */
import MapLoader from "./initMapUtils";
export default {
  name: "cc-gd-map",
  props: {
    /**
     * 地图key值
     */
    mapkey: {
      type: String,
      default: "",
    },
    /**
     * 地图缩放级别
     */
    zoom: {
      type: Number,
      default: 13,
    },
    /**
     * 回显经纬度（编辑|详情）
     */
    pointValue: {
      type: Array,
      default: () => [],
    },
    /**
     * 回显经纬度（编辑|详情）
     */
    ggPointValue: {
      type: Object,
      default: () => {
        // 默认纽约
        return { lat: 40.755465, lng: -73.9869088 };
      },
    },
    /**
     * 动态地图id
     */
    lineIndex: {
      type: Number,
      default: 0,
    },
    /**
     * 是否是详情页
     */
    isInfoMap: {
      type: Boolean,
      default: false,
    },
    /**
     * 判断地图
     */
    isMap: {
      type: Boolean,
      default: true,
    },
    /**
     * 派工台地图隐藏工具栏
     */
    isHide: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      mapError: false, //地图是否加载成功
      showSearch: false, // 展示搜索信息
      searchKey: "", // 搜索关键字
      searchList: [], // 搜索结果列表
      mapObject: null, // 地图对象
      geocoder: null, // 地图定位解析
      poiSearch: null, // poi搜索
      poiPanel: false, // poi搜索
      maker: null, // 地图标记
      checkedInfo: {}, //回传地图相关信息
      locationArr: [], //地图经纬度
      mapDialogTop: "105px", //全屏展示按钮top值
      iconImg: "#icon-a-Cancelfullscreen", //全屏展示按钮svg
      isEnlarge: false, //判断是否放大缩小地图
      mapHeight: "520px", //地图高度
      showSearchBar: true, //是否展示搜索框及关闭按钮
      ggMapObject: null, // 谷歌地图对象
      ggMaker: null, // 谷歌地图标记
      GGgeocoder: null, // 谷歌地图定位解析
      // 直辖市
      zhixiashi: ["北京市", "天津市", "上海市", "重庆市"],
    };
  },
  methods: {
    /**
     * 初始化
     */
    init() {
      let key = this.mapkey;
      this.checkedInfo = {};
      let that = this;
      if (key.length === 0) {
        that.mapError = true;
      } else {
        // 如果是高德
        if (this.isMap) {
          // 加载高德地图
          MapLoader(key, "Amap").then(
            () => {
              that.initMap();
              that.initFun();
            },
            (e) => {}
          );
        } else {
          // 加载谷歌地图
          MapLoader(key, "Gmap").then(
            (map) => {
              that.createMap();
              this.initFun();
              if (that.ggPointValue) {
                that.ggMapObject.setCenter(that.ggPointValue);
                that.ggMarker.setPosition(that.ggPointValue);
              }
            },
            (e) => {}
          );
        }
      }
    },
    // 实例化地图
    initMap() {
      let that = this;
      that.mapObject = new AMap.Map("js-container" + that.lineIndex, {
        resizeEnable: true,
        zoom: 14,
      });
      // 地址与坐标转换
      that.geocoder = new AMap.Geocoder({
        city: "010", // 城市设为北京，默认：“全国”
        radius: 1000, // 范围，默认：500
      });
      // poi搜索插件
      this.poiPanel = true;
      !that.isInfoMap && AMap.plugin("AMap.PlaceSearch", function () {
        // poi搜索
        that.poiSearch = new AMap.PlaceSearch({
          // 文档：https://developer.amap.com/api/javascript-api/reference/search#m_AMap.PlaceSearch
          extensions: "all",
          panel: "poiPanelId",
          pageSize: 5, // 单页显示结果条数
          pageIndex: 1, // 页码
          map: that.mapObject,
          type: "汽车服务|汽车销售|汽车维修|摩托车服务|餐饮服务|购物服务|生活服务|体育休闲服务|医疗保健服务|住宿服务|风景名胜|商务住宅|政府机构及社会团体|科教文化服务|交通设施服务|金融保险服务|公司企业|道路附属设施|地名地址信息|公共设施",
          // type: "商务住宅|政府机构及社会团体|公司企业|地名地址信息",
        });
        // poi搜索数据值变化
        that.poiSearch.on("selectChanged", (info) => {
          let allAdd = {};
          allAdd = {
            addressComponent: {
              country: "中国",
              province: info.selected.data.pname,
              city: info.selected.data.cityname,
              district: info.selected.data.adname,
            },
            formattedAddress:
              (that.zhixiashi.includes(info.selected.data.pname)
                ? ""
                : info.selected.data.pname) +
              info.selected.data.cityname +
              info.selected.data.adname +
              info.selected.data.address +
              info.selected.data.name,
          };
          that.locationArr = [
            info.selected.data.location.lng,
            info.selected.data.location.lat,
          ];
          that.checkedInfo = allAdd;
        });
      });

      // 地图加载完成咯
      that.mapObject.on("complete", () => {
        // that.mapObject.setZoomAndCenter(14, [lat,lng]);
      });

      that.marker = new AMap.Marker();
      if (that.pointValue && that.pointValue.length !== 0) {
        that.mapObject.setZoomAndCenter(14, that.pointValue);
        that.mapObject.add(that.marker);
        that.marker.setPosition(that.pointValue);
      }

      !that.isInfoMap && that.mapObject.on("click", (e) => {
        that.showSearch = false;
        // 清除之前的marker
        if (that.marker) {
          that.marker.setMap(null);
          that.marker = null;
        }

        // 地图上点击生成 marker
        that.marker = new AMap.Marker({
          position: e.lnglat,
          map: that.mapObject,
        });
        that.poiSearch.searchNearBy(
          "",
          e.lnglat,
          200,
          function (status, result) {
            if (status === "complete" && result.info === "OK") {
              that.poiPanel = true;
            } else {
              that.poiPanel = false;
            }
          }
        );
        that.mapObject.setFitView(
          that.marker,
          false,
          [100, 100, 100, 100],
          14
        );
        // this.geocoder.getAddress(e.lnglat, (status, result) => {
        //   if (status === "complete" && result.info === "OK") {
        //     console.log(result);
        //     let addr =
        //       result.regeocode.addressComponent.country +
        //       result.regeocode.addressComponent.province +
        //       result.regeocode.addressComponent.district +
        //       result.regeocode.addressComponent.township +
        //       result.regeocode.addressComponent.street +
        //       result.regeocode.addressComponent.streetNumber;
        //     // result为对应的地理位置详细信息
        //     this.searchKey = result?.regeocode?.formattedAddress;
        //     this.checkedInfo = result.regeocode;
        //     this.checkedInfo.formattedAddress = addr;
        //   }
        // });

        // 回填顶部输入框 当前点击的地址的中文数据
        //  this.searchKey
      });
    },
    // 实例化谷歌地图
    createMap() {
      let that = this;
      that.ggMapObject = new google.maps.Map(
        document.getElementById("gg-container" + this.lineIndex),
        {
          center: { lng: 116.404, lat: 39.915 },
          zoom: 12,
          // scrollwheel: false, // 滚轮
          disableDefaultUI: true, // 禁用默认ui
        }
      );
      that.ggGeocoder = new google.maps.Geocoder();
      that.postCenterInfo(that.ggMapObject.center);
      // 地图缩放
      that.ggMapObject.addListener("zoom_changed", () => {
        that.postCenterInfo(that.ggMapObject.center);
        that.$emit("changeZoom", that.ggMapObject.getZoom());
      });
      let marker = {
        position: that.ggMapObject.center,
        map: that.ggMapObject,
        title: "Click to zoom",
      };
      that.ggMarker = new google.maps.Marker(marker);
      that.ggMapObject.addListener("click", (mapsMouseEvent) => {
        addMarker(mapsMouseEvent.latLng, that.ggMapObject);
      });
      function addMarker(latLng, map) {
        that.ggMarker.setPosition(latLng);
        that.postPointInfo(latLng);
      }
      that.ggMapObject.addListener("dragend", () => {
        that.postCenterInfo(that.ggMapObject.center);
      });
      that.ggMapObject.addListener("center_changed", () => {});
      // 标记点击事件
      that.ggMarker.addListener("click", () => {
        that.ggMapObject.setZoom(16);
        that.ggMapObject.setCenter(that.ggMarker.getPosition());
        that.postCenterInfo(that.ggMarker.getPosition());
      });
    },
    /**
     * 放大缩小地图
     */
    enlargeFun() {
      this.isEnlarge = !this.isEnlarge;
      if (this.isEnlarge) {
        this.iconImg = "#icon-a-Cancelfullscreen";
        this.showSearchBar = false;
        this.mapHeight = document.documentElement.clientHeight + "px";
        this.mapDialogTop = "29px";
        this.$parent.$parent.mapTop = "0";
        this.$parent.$parent.mapWidth = "100%";
      } else {
        this.initFun();
      }
    },
    /**
     * 初始化缩小地图
     */
    initFun() {
      this.iconImg = "#icon-a-Fullscreen";
      this.showSearchBar = true;
      this.mapHeight = "520px";
      this.mapDialogTop = "105px";
      this.$parent.$parent.mapTop = "6%";
      this.$parent.$parent.mapWidth = "80%";
    },
    /**
     * 地图搜索框失去焦点
     */
    handlebulr() {},
    /**
     * 地图搜索框获得焦点
     */
    handleFocus() {
      this.showSearch = this.searchKey.length === 0 ? false : true;
    },
    /**
     * 搜索后地图下拉列表
     */
    handleSearch() {
      var keywords = this.searchKey;
      let that = this;
      // 高德地图搜索
      if (this.isMap) {
        this.mapObject.plugin("AMap.Autocomplete", function () {
          // 实例化Autocomplete
          var autoOptions = {
            // city 限定城市，默认全国
            city: "全国",
            datatype: "all",
          };
          var autoComplete = new AMap.Autocomplete(autoOptions);
          autoComplete.search(keywords, function (status, result) {
            // 搜索成功时，result即是对应的匹配数据
            if (result.info === "OK") {
              that.searchList = result.tips;
              that.showSearch = true;
            }
          });
        });
      } else {
        // 谷歌地图搜索
        that.ggGeocoder
          .geocode({ address: keywords })
          .then((result) => {
            const { results } = result;
            that.searchList = results;
            that.showSearch = true;
          })
          .catch((e) => {});
      }
    },
    /**
     * 选择地址
     */
    chooseLocation(item) {
      if (this.isMap) {
        this.showSearch = false;
        // 清空定位
        this.locationArr = [];
        this.mapObject.remove(this.marker);
        let that = this;
        this.locationArr = [item.location.lng, item.location.lat];
        this.searchKey = item.name;
        // 设置缩放级别和中心点
        this.mapObject.setZoomAndCenter(14, that.locationArr);
        // 在新中心点添加 marker
        /* eslint-disable no-undef */
        this.marker = new AMap.Marker({
          map: that.mapObject,
          position: that.locationArr,
        });
        this.geocoder.getAddress(this.locationArr, function (status, result) {
          that.checkedInfo = result.regeocode;
          that.checkedInfo.formattedAddress =
            item.district + item.address + item.name;
        });
        this.poiSearch.searchNearBy(
          "",
          that.locationArr,
          200,
          function (status, result) {
            if (status === "complete" && result.info === "OK") {
              that.poiPanel = true;
            } else {
              that.poiPanel = false;
            }
          }
        );
        this.mapObject.setFitView(this.marker, false, [100, 100, 100, 100], 14);
      } else {
        item.geometry.location = item.geometry.location.toJSON();
        this.searchKey = item.formatted_address;
        this.checkedInfo = item;
        this.ggMapObject.setCenter(item.geometry.location);
        this.postCenterInfo(item.geometry.location);
        this.ggMarker.setPosition(item.geometry.location);
      }
    },
    /**
     * 确定
     */
    handleChecked() {
      if (this.isMap) {
        // 如果是高德地图
        this.poiPanel = false;
        this.$emit("chooseLocation", this.checkedInfo, this.locationArr);
      } else {
        // 截取街道地址
        let street = this.checkedInfo?.formatted_address?.split(",")[0];
        this.checkedInfo.formatted_address = street;
        this.$emit("chooseLocation", this.checkedInfo);
      }
      this.showSearch = false;
    },
    /**
     * 清空
     */
    handleClear() {
      this.$emit("clearLocation");
      this.showSearch = false;
    },
    /**
     * 关闭弹窗
     */
    bigClose() {
      this.poiSearch?.clear();
      this.$emit("bigClose");
    },
    postPointInfo(latLng) {
      let that = this;
      let addr = "";
      that.ggGeocoder
        .geocode({ location: latLng })
        .then((response) => {
          if (response.results[0]) {
            //
            addr = response.results[0].formatted_address;
          } else {
            addr = "No results found";
          }
          this.$emit("updatePoint", addr, latLng.toJSON());
        })
        .catch((e) => {
          addr = "Geocoder failed due to: " + e;
          this.$emit("updatePoint", addr, latLng.toJSON());
        });
    },
    postCenterInfo(latLng) {
      let that = this;
      let addr = "";
      that.ggGeocoder
        .geocode({ location: latLng })
        .then((response) => {
          if (response.results[0]) {
            addr = response.results[0].formatted_address;
          } else {
            addr = "No results found";
          }
          this.$emit("updateCenter", addr, latLng.toJSON());
        })
        .catch((e) => {
          addr = "Geocoder failed due to: " + e;
        });
    },
    //派工台地图调用---定位服务预约点
    DispatchFun(keywords) {
      let that = this;
      let marker = {
        position: that.ggMapObject.center,
        map: that.ggMapObject,
        icon: require("@/assets/dispatchingUnits/centerPoint.svg"),
        draggable: false,
        animation: google.maps.Animation.BOUNCE,
        title: "",
      };
      that.ggMarker = new google.maps.Marker(marker);
      that.ggGeocoder.geocode(
        { address: keywords },
        function geoResults(results, status) {
          if (status == google.maps.GeocoderStatus.OK) {
            let item = results.length > 1 ? results[1] : results[0];
            item.geometry.location = item.geometry.location.toJSON();
            that.ggMapObject.setCenter(item.geometry.location);
            that.postCenterInfo(item.geometry.location);
            that.ggMarker.setPosition(item.geometry.location);
          } else {
          }
        }
      );
    },
    //派工台地图调用---展示所有可用服务资源
    showAllService(params) {
      let that = this;
      let markerItem;
      that.arr = [];
      params.forEach((item, index) => {
        item.position = {
          lat: JSON.parse(item.lat),
          lng: JSON.parse(item.lng),
        };
        markerItem = new google.maps.Marker({
          position: item.position,
          map: that.ggMapObject,
          icon: require("@/assets/dispatchingUnits/roundingPoint.svg"),
          zIndex: index,
          resourcesParams: item,
        });
        that.arr.push(markerItem);
      });
      that.arr.forEach((val) => {
        //自定义信息窗口
        let gg = new google.maps.InfoWindow({
          content: `<div style="width:300px;height:290px" ><div style="display:flex;">
          <svg class="icon" aria-hidden="true" style="width:38px;height:38px">
                  <use xlink:href="#icon-servePeople"></use>
                </svg>
          <div>
           <h4 style="margin:0">${this.$i18n.t(
             "label.service.ganttBaiduMap.service.resourname"
           )}</h4>
           <span style="margin:0">${val.resourcesParams.spotName}</span>
          </div>
         </div>
         <div>
         <ul id="InfoBody">
         </ul>
         </div>
         <div style="padding:15px;display:flex;justify-content: center;
           align-items: center;" onclick="DispatchAdd()">
           <span style="background: #006dcc;color:#fff;padding:10px;cursor: pointer;">${this.$i18n.t(
             "label.service.ganttBaiduMap.assign.service"
           )}</span>
         </div>
         </div>`,
        });
        //点击窗口信息
        google.maps.event.addListener(val, "click", function (e) {
          gg.open(that.ggMapObject, val);
          that.$emit("showDetail", val.resourcesParams);
        });
      });
    },
    //派工台地图调用---展示服务资源详细信息卡片
    showServiceDetail(params, spotID) {
      if (params.length != 0) {
        let body = document.getElementById("InfoBody");
        body.innerHTML = "";
        params.forEach((item) => {
          let li = `<li style="border-bottom:1px solid #dcdcdc">
               <h4>${item.name}</h4>
               <div style="display:flex;justify-content: space-between;">
               <div>
                <p class="">
                      ${this.$i18n.t(
                        "label.service.ganttBaiduMap.scheduled.starttime"
                      )}：${item.schedstarttime}
                    </p>
                    <p class="">
                      ${this.$i18n.t(
                        "label.service.ganttBaiduMap.scheduled.endtime"
                      )}：${item.schedendtime}
                    </p>
                    <p class="">${this.$i18n.t("AD")}：${item.address}</p>
               </div>
                <h4>${item.status}</h4>
               </div>
             </li>`;
          body.insertAdjacentHTML("beforeend", li);
        });
      }
    },
    //分配服务预约
    DispatchAdd() {
      this.$emit("AssignAppointment");
    },
  },
  mounted() {
    window.DispatchAdd = this.DispatchAdd;
  },
  watch: {
    /**
     * 缩放级别
     */
    zoom() {
      this.mapObject.setZoom(this.zoom);
    },
    /**
     * 关键字筛选
     */
    searchKey() {
      if (this.searchKey.length === 0) {
        this.showSearch = false;
        return false;
      } else {
        this.handleSearch();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep.el-gd-map .map-box .search {
  top: 0;
  left: 0;
}

.map-box {
  position: relative;
  min-width: 510px;
  ::v-deep .search {
    position: relative;
    background: rgba(236, 236, 236, 0.8999999761581421);
    box-shadow: 0px 2px 0px 1px rgba(0, 0, 0, 0.25);
    opacity: 1;
    height: 76px;
    // width: auto !important;
    padding-right: 20px;
    width: 100%;
    margin-bottom: 4px;
    min-width: 410px;
    display: flex;
    align-items: center;
    .search1 {
      display: flex;
      align-items: center;
      .show-address {
        width: 500px;
        padding: 0 10px;
        text-align: center;
        border: 1px solid silver;
        height: 44px;
        display: flex;
        margin: 0 10px;
        align-items: center;
        border-radius: 5px;
        justify-content: center;
        overflow: hidden;
      }
      // margin-top: 16px;
    }
    .el-input {
      // width: 386px;
      width: 100%;
      min-width: 285px;
      height: 44px;
      margin-left: 24px;
      margin-right: 16px;
      // margin-top: 16px;
      .el-input__inner {
        // width: 386px;
        width: 100%;
        min-width: 285px;
        height: 44px;
        border: 1px solid #006dcc;
      }
    }
    .btn {
      min-width: 76px;
      height: 44px;
      cursor: pointer;
      line-height: 1;
      span {
        font-size: 14px;
      }
    }
    .btn_map {
      button {
        width: 112px;
        height: 44px;
        span {
          font-size: 14px;
        }
      }
      float: right;
      // margin-top: 16px;
    }
  }
  .close_box {
    width: 44px;
    height: 44px;
    border: 1px solid #c8c8c8;
    background: #fff;
    border-radius: 3px;
    position: absolute;
    // top: 16px;
    top: 15px;
    right: 24px;
    cursor: pointer;
    z-index: 999;
    svg {
      width: 16px;
      height: 16px;
      margin: 13px;
    }
  }
  .search-result {
    background: #fff;
    max-height: 300px;
    overflow: auto;
    // margin: 10px 23px;
    border: 1px solid lightgray;
    border-radius: 5px;
    min-width: 387px;
    // position: absolute;
    // top: 55px;
    z-index: 999;

    .search-result-item {
      cursor: pointer;
      padding: 3px 10px;
      color: #666666;
    }
    .search-result-item:hover {
      color: cornflowerblue;
    }
  }
  .enlarge_box {
    width: 63px;
    height: 63px;
    background: #006dcc;
    border-radius: 50%;
    box-shadow: 0px 0px 20px 1px rgba(0, 0, 0, 0.4000000059604645);
    opacity: 1;
    z-index: 999;
    position: absolute;
    right: 24px;
    cursor: pointer;
    text-align: center;
    svg {
      width: 25px;
      height: 25px;
      margin: 9px 0 0 0;
    }
    span {
      display: block;
      color: #fff;
      font-size: 12px;
    }
  }
}
#poiPanelId {
  position: absolute;
  background-color: white;
  max-height: 90%;
  overflow-y: auto;
  top: 86px;
  right: 10px;
  width: 280px;
  // border-bottom: solid 1px silver;
}
::v-deep .amap-pl-pc .poi-img {
  display: none;
}
::v-deep .amap-pl-pc .poi-more {
  display: none;
}
.search-box {
  position: absolute;
  z-index: 100;
  padding: 10px;
  .search-input {
    width: 380px;
  }
  ::v-deep .el-input .el-input__inner {
    height: 44px;
  }
}
</style>


